import React from 'react'
import { get } from 'lodash'
import Layout from '../components/layout/Layout'
import Img from 'gatsby-image'
import SEO from '../components/seo/SEO'
import { graphql } from 'gatsby'

class ContactUs extends React.Component {
  render() {
    const pageTitle = 'Contact Us'
    const pageDescription = 'We are here to answer any questions you may have about Weighted Blanket Guides, our site or the guides. Reach out to us and we’ll respond as soon as we can.'

    const siteConfig = get(this, 'props.data.site.siteMetadata')
    const seoConfig = {
      ...siteConfig,
      url: get(this, 'props.location.href', `https://www.weightedblanketguides.com/contact-us/`),
      title: `${pageTitle} - ${get(this, 'props.data.site.siteMetadata.name')}`,
      description: pageDescription
    }

    const content = `
      <p>All our reviews and informational content are un-sponsored, which guarantees that any advice coming from us is honest and impartial.</p>
      <p>However, we work with affiliate networks, therefore we may make money if you make a purchase after clicking one of the links on our site. In case you are disappointed with your purchase and return the item, then we lose our commission, which means we really want you to be happy with your purchase.</p>
      <p>We are here to answer any questions you may have about Weighted Blanket Guides, our site or the reviews.&nbsp;Reach out to us and we&rsquo;ll respond as soon as we can.</p>
      <p>Read <a href="/about-us">about us, the authors, here</a></p>
      <h3>How to Reach Us</h3>
      <p>
        Please use any of these email addresses:
        <br/><a href="mailto:weightedblanketguides@gmail.com" target="_blank" rel="noopener">weightedblanketguides@gmail.com</a>
        <br/><a href="mailto:contact@weightedblanketguides.com" target="_blank" rel="noopener">contact@weightedblanketguides.com</a>
      </p>
      <p>
        You can find us on these social media platforms:<br/>
        <a href="https://www.linkedin.com/company/weighted-blanket-guides/" target="_blank"><img alt="" width="70" src="/linkedin-logo.png"/></a>
        <a href="https://www.facebook.com/weightedblanketguides/" target="_blank"><img alt="" width="60" src="/facebook-logo.png"/></a>
      </p>
      <h4>Company Address</h4>
      <p>Suite 904, 2600 N.Central Ave, Phoenix AZ 85004 United States</p>
      <p>&nbsp;</p>`

    return (
      <Layout location={this.props.location}>
        <SEO postData={{}} postImage={''} isBlogPost={false} config={seoConfig}/>
        <div className="page__container">
          <h1>{pageTitle}</h1>
          <Img fluid={{...this.props.data.file.childImageSharp.fluid}} alt={pageTitle}/>
          <br/>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </Layout>
    )
  }
}

export default ContactUs

export const ContactUsQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        name
        description
        siteUrl
      }
    }
    file(relativePath: {eq: "contact-us-weightedblanketguides.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 740) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
